<template>
  <div :style="CSS" :class="CSSClasses" class="tiles-container">
    <design-viewer
      v-for="(tile, index) in tilesData"
      :key="index"
      :blocks="tileTemplate"
      :data="tile"
      :style="`height: 200px; width: ${listWidth}px;display:inline-block;${tileStyle}`"
    ></design-viewer>
  </div>
</template>

<script>
import mixin from '@/components/InterfaceEditor/components/mixins'
import DesignViewer from '@/components/InterfaceEditor/components/basic/HtmlInterfaceDesigner/viewer'

export default {
  name: 'tiles',
  components: { DesignViewer },
  mixins: [mixin],
  props: {
    listWidth: {
      type: Number,
      default: 300,
      description: 'Ширина плитки'
    },
    tileTemplate: {
      type: Array,
      default: () => {
        return []
      },
      options: {
        width: 300,
        height: 200
      },
      description: 'Шаблон плитки',
      editor: 'HtmlDesign'
    },
    tileStyle: {
      type: String,
      description: 'Стиль плитки',
      default: 'background: #FFFFFF;border-radius: 8px;cursor: pointer;margin: 6px;'
    }
  },
  data () {
    return {
      tilesData: [{ id: 2, name: 'test' }, { id: 22, name: 'test22' }]
    }
  }
}
</script>

<style scoped>
.tiles-container {
  height: 100%;
}
</style>
